import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import {
  BreadcrumbComponent,
  BreadcrumbItem,
} from '@components/common/breadcrumb/breadcrumb.component';
import {
  getAnalyzedQuestionIdRoute,
  getQuestionIdRoute,
  RouteLabel,
} from '@utils/routes';
import {
  PageRequest,
  PageResponse,
  PfAlertComponent,
  TableColumn,
} from 'pf-ui';
import { DatePipe, NgIf } from '@angular/common';
import { AgentService } from '@services/agent.service';
import { TabViewChangeEvent, TabViewModule } from 'primeng/tabview';
import { PluralizePipe } from '@app/pipes/pluralize.pipe';
import { DashboardQuestionsListComponent } from '@pages/home/dashboard-questions-list/dashboard-questions-list.component';
import { QuestionService } from '@services/question.service';
import {
  QuestionCountByStatus,
  QuestionStatus,
} from '@models/question/questionStatus.model';
import { Question } from '@models/question/question.model';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import {
  hasAnyAuthorities,
  hasAuthority,
} from '@models/user/agent-detail.model';
import { ExamSessionsComponent } from '@pages/home/exam-sessions/exam-sessions.component';
import { UserExamByCategoryComponent } from '@components/statistics/overview/user-exam-by-category/user-exam-by-category.component';
import { objectEqual } from '@utils/objectHelper';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    TranslateModule,
    RouterLink,
    BreadcrumbComponent,
    PfAlertComponent,
    NgIf,
    TabViewModule,
    DashboardQuestionsListComponent,
    DatePipe,
    ButtonModule,
    TooltipModule,
    ExamSessionsComponent,
    UserExamByCategoryComponent,
  ],
  templateUrl: './home.component.html',
})
export class HomeComponent {
  pipe: PluralizePipe = new PluralizePipe();
  breadcrumbs: BreadcrumbItem[] = [{ label: RouteLabel.Home }];
  waitingValidationQuestions?: PageResponse<Question>;
  draftQuestions?: PageResponse<Question>;
  rejectedQuestions?: PageResponse<Question>;
  agent = this.agentService.signalCurrent;
  public activeTabIndex = 0;

  questionCountByStatus?: QuestionCountByStatus[];

  waitingValidationQuestionsColumns: TableColumn[] = [
    { name: 'Description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
    { name: 'Catégorie(s)' },
    { name: 'Etat' },
    {
      name: 'Date de mise en attente de validation',
      sortName: 'lastModifiedDate',
    },
    { name: '' },
  ];

  draftQuestionsColumns: TableColumn[] = [
    { name: 'Description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
    { name: 'Catégorie(s)' },
    { name: 'Etat' },
    { name: 'Date de dernière modification', sortName: 'lastModifiedDate' },
    { name: '' },
  ];

  rejectedQuestionsColumns: TableColumn[] = [
    { name: 'Description' },
    { name: 'Média' },
    { name: 'Thème(s)' },
    { name: 'Catégorie(s)' },
    { name: 'Etat' },
    { name: 'Date de rejet', sortName: 'lastModifiedDate' },
    { name: '' },
  ];

  pageRequest: PageRequest = {
    order: 'DESC',
    sort: 'lastModifiedDate',
  };

  constructor(
    public agentService: AgentService,
    public questionService: QuestionService,
    public fb: FormBuilder,
  ) {
    this.getQuestionCount();
  }

  onTabChange(event: TabViewChangeEvent): void {
    this.activeTabIndex = event.index;

    switch (this.activeTabIndex) {
      case 0:
        this.waitingValidationQuestionParameterChangedHandler(this.pageRequest);
        break;
      case 1:
        this.draftQuestionParameterChangedHandler(this.pageRequest);
        break;
      case 2:
        this.rejectedQuestionParameterChangedHandler(this.pageRequest);
        break;
      default:
        return;
    }
  }

  get hasPermissionToQuestionActions(): boolean {
    return hasAuthority(this.agent(), 'question.view');
  }

  get hasPermissionToManageSession(): boolean {
    return hasAnyAuthorities(this.agent(), [
      'session.manage.all',
      'session.manage.external',
    ]);
  }

  getQuestionCount(): void {
    if (!this.hasPermissionToQuestionActions) return;
    this.questionService
      .getQuestionCountByStatus()
      .subscribe(
        (questionCountByStatus: QuestionCountByStatus[]) =>
          (this.questionCountByStatus = questionCountByStatus),
      );
  }

  waitingValidationQuestionParameterChangedHandler(event: PageRequest): void {
    if (this.activeTabIndex !== 0) return;
    if (
      this.waitingValidationQuestions != null &&
      objectEqual(this.pageRequest, event)
    )
      return;

    this.pageRequest = { ...this.pageRequest, ...event };
    this.questionService
      .listPaginated({
        ...this.pageRequest,
        global_filter_fields: 'questionStatus',
        global_filter: [
          QuestionStatus.PENDING_VALIDATION_0_2,
          QuestionStatus.PENDING_VALIDATION_1_2,
        ],
      })
      .subscribe(
        (waitingValidationQuestions) =>
          (this.waitingValidationQuestions = waitingValidationQuestions),
      );
  }

  draftQuestionParameterChangedHandler(event: PageRequest): void {
    if (this.activeTabIndex !== 1) return;
    if (this.draftQuestions != null && objectEqual(this.pageRequest, event))
      return;

    this.pageRequest = { ...this.pageRequest, ...event };
    this.questionService
      .listPaginated({
        ...this.pageRequest,
        global_filter_fields: 'questionStatus',
        global_filter: [QuestionStatus.DRAFT],
      })
      .subscribe((draftQuestions) => (this.draftQuestions = draftQuestions));
  }

  rejectedQuestionParameterChangedHandler(event: PageRequest): void {
    if (this.activeTabIndex !== 2) return;
    if (this.rejectedQuestions != null && objectEqual(this.pageRequest, event))
      return;

    this.pageRequest = { ...this.pageRequest, ...event };
    this.questionService
      .listPaginated({
        ...this.pageRequest,
        global_filter_fields: 'questionStatus',
        global_filter: [QuestionStatus.REJECTED],
      })
      .subscribe(
        (rejectedQuestions) => (this.rejectedQuestions = rejectedQuestions),
      );
  }

  getQuestionCountByStatus(...status: QuestionStatus[]): number {
    return (
      this.questionCountByStatus?.reduce((total, questionCountByStatus) => {
        if (status.includes(questionCountByStatus.status as QuestionStatus)) {
          total += questionCountByStatus.count;
        }
        return total;
      }, 0) ?? 0
    );
  }

  get hasRoles(): boolean {
    const roles = this.agent()?.agentRoles ?? [];
    return roles.length > 0;
  }

  get waitingValidationQuestionsTitle(): string {
    return this.pipe.transform(
      this.getQuestionCountByStatus(
        QuestionStatus.PENDING_VALIDATION_0_2,
        QuestionStatus.PENDING_VALIDATION_1_2,
      ),
      'question en attente de validation',
      'questions en attente de validation',
    );
  }

  get draftQuestionsTitle(): string {
    return this.pipe.transform(
      this.getQuestionCountByStatus(QuestionStatus.DRAFT),
      'question en brouillon',
      'questions en brouillon',
    );
  }

  get rejectedQuestionsTitle(): string {
    return this.pipe.transform(
      this.getQuestionCountByStatus(QuestionStatus.REJECTED),
      'question rejetée',
      'questions rejetées',
    );
  }

  protected readonly RouteLabel = RouteLabel;
  protected readonly getAnalyzedQuestionIdRoute = getAnalyzedQuestionIdRoute;
  protected readonly getQuestionIdRoute = getQuestionIdRoute;
}
