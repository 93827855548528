<div
  class="section section-stats h-full flex flex-column justify-content-between"
>
    <div>
        <div class="flex align-items-center gap-2 mb-4">
            <div
                    class="flex border-round-xl w-2rem h-2rem bg-blue-gray-200 text-primary p-1 justify-content-center align-items-center"
            >
                <i class="pi pi-calendar"></i>
            </div>
            <h3 class="my-0 text-xl font-medium">Prochaines Sessions d'examen</h3>
        </div>

        @if (examSessions()?.content?.length === 0) {
          <span class="font-light text-gray-500"> Aucune session prévue</span>
        } @else {
            <div class="grid" *ngFor="let examSession of examSessions()?.content">
                <div class="mt-3 text-gray-500">
                    @if (!examSessions()) {
                        <p-skeleton/>
                    } @else {
                        <span
                                class="cursor-pointer hover:underline"
                                [routerLink]="getSessionIdRoute(examSession.id)"
                        >{{ getFrenchSessionDate(examSession.sessionDate) }}</span
                        >
                    }
                </div>
            </div>
        }
    </div>

  <div class="flex justify-content-end mt-4">
    <pf-action-button
      type="previous"
      customIconClass=" "
      styleClass="p-button-tertiary border-none text-sm justify-content-start"
      label="Voir toutes les sessions"
      [routerLink]="'/' + AdminRoute.Sessions"
    ></pf-action-button>
  </div>
</div>
