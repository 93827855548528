<pf-table
  [response]="questions()"
  [isLazyMode]="true"
  [noResultLabel]="noResultLabel()"
  (onParametersChanged)="onParametersChangedHandler($event)"
  [rows]="5"
  [displaySkeleton]="true"
  [columns]="columns()"
  [showCaption]="false"
  [showSearch]="false"
  dataKey="id"
  [rowsPerPageOptions]="[5, 10]"
  styleClass="mb-5"
>
  <ng-template #content let-question>
    <tr [hidden]="isTableLoading()">
      <td tooltipPosition="top" [pTooltip]="getTooltipDescription(question)">
        {{ question.description | truncate: MAX_QUESTION_DESCRIPTION_LENGTH }}
      </td>
      <td>
        <app-thumbnail [media]="question.picture"></app-thumbnail>
      </td>
      <td>{{ renderList(question.themes) }}</td>
      <td>{{ renderList(question.categories) }}</td>
      <td>
        <app-question-status
          [status]="question.questionStatus"
        ></app-question-status>
      </td>
      <ng-container
        *ngTemplateOutlet="customColumn; context: { $implicit: question }"
      >
      </ng-container>
    </tr>
    @if (isTableLoading()) {
      <tr>
        @for (col of columns(); track col) {
          <td>
            <p-skeleton />
          </td>
        }
      </tr>
    }
  </ng-template>
</pf-table>
