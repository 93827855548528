import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  PfActionButtonComponent,
  PfCardComponent,
  PfFormSummaryEditionWrapperComponent,
} from 'pf-ui';
import { TableModule } from 'primeng/table';
import { PluralizePipeModule } from '@app/pipes/pluralize.pipe';
import { RouterLink } from '@angular/router';
import { SkeletonModule } from 'primeng/skeleton';
import { ChartModule } from 'primeng/chart';
import { ButtonModule } from 'primeng/button';
import { ExamSessionService } from '@services/examSession.service';
import { AdminRoute, getSessionIdRoute } from '@utils/routes';
import { formatFrenchLocaleSessionDate } from '@utils/examSessions';
import { DateTranslateService } from '@services/dateTranslate.service';
import { AgentService } from '@services/agent.service';
import { hasAnyAuthorities } from '@models/user/agent-detail.model';
import {
  ADMIN_TENANT,
  EXTERNAL_SUPERVISOR,
  INTERNAL_SUPERVISOR,
  QUESTION_MANAGER,
  SERIES_MANAGER,
} from '@models/user/role-detail.model';
import { getDateAtStartOfTheDay } from '@utils/dateHelper';

const DEFAULT_LIMIT_EXAM_SESSIONS = 5;

@Component({
  selector: 'app-home-exam-sessions',
  standalone: true,
  imports: [
    CommonModule,
    PfCardComponent,
    TableModule,
    PluralizePipeModule,
    PfFormSummaryEditionWrapperComponent,
    RouterLink,
    SkeletonModule,
    ChartModule,
    ButtonModule,
    PfActionButtonComponent,
  ],
  templateUrl: './exam-sessions.component.html',
})
export class ExamSessionsComponent {
  examSessions = this.examSessionService.signalExamSessions;
  agent = this.agentService.signalCurrent;

  constructor(
    public examSessionService: ExamSessionService,
    public dateTranslateService: DateTranslateService,
    public agentService: AgentService,
  ) {
    this.examSessionService
      .getAllExamSessions(
        getDateAtStartOfTheDay(),
        this.isSupervisor ? this.agent()?.island?.id : undefined,
        {
          page_size: DEFAULT_LIMIT_EXAM_SESSIONS,
          global_filter_fields: 'examSessionStep',
          global_filter: 'NOT_SESSION_END',
        },
      )
      .subscribe();
  }

  get isSupervisor(): boolean {
    return (
      hasAnyAuthorities(this.agent(), [EXTERNAL_SUPERVISOR]) &&
      !hasAnyAuthorities(this.agent(), [
        INTERNAL_SUPERVISOR,
        ADMIN_TENANT,
        QUESTION_MANAGER,
        SERIES_MANAGER,
      ])
    );
  }

  getFrenchSessionDate(sessionDate?: string): string {
    return `Session du ${formatFrenchLocaleSessionDate(
      this.dateTranslateService.getMonthLabels(),
      sessionDate,
    )}`;
  }

  protected readonly AdminRoute = AdminRoute;
  protected readonly getSessionIdRoute = getSessionIdRoute;
}
